<template>
  <div class="publish_container global_container_center">
    <div class="publish_main">
      <div class="publish_title">发布成功</div>
      <div class="publish_tip">请选择帖子征集嘉宾类型</div>
      <div class="publish_tip">
        指定邀请：选择【指定邀请】后，只有被邀请的专家方可看到该贴。若邀请的专家未同意邀请，后续也可转为公开征集，为您匹配感兴趣的专家
      </div>
      <div class="publish_tip">
        公开征集：选择【公开征集】后，感兴趣的嘉宾将通过平台主动参会，且您不能再指定某一位嘉宾入会
      </div>
      <div class="publish_button_group">
        <CustomButton size="small" type="info" @click="goToHome"
          >公开征集</CustomButton
        >
        <CustomButton size="small" type="primary" @click="goToInvite"
          >指定邀请</CustomButton
        >
      </div>
    </div>
  </div>
</template>
<script>
import CustomButton from "../../unit/CustomButton.vue";
export default {
  name: "publish",
  components: {
    CustomButton,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    goToHome() {
      this.$router.push({ path: "/home" });
    },
    goToInvite() {
      if (this.loading) return;
      this.loading = true;
      this.$axios
        .patch(this.$urls.live.mode, { ...this.$route.query, post_type: 1 })
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          this.$router.push({ path: "/myMeetList", query: this.$route.query });
        })
        .catch(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          this.$tips.error({ text: "操作失败" });
        });
    },
  },
};
</script>
<style>
.publish_container {
  height: 100%;
  width: 100%;
  font-size: 0.9rem;
  font-weight: 600;
}
.publish_title {
  margin-bottom: 3rem;
}
.publish_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5rem;
  /* text-align: center; */
}
.publish_tip {
  padding: 0 2rem;
  margin-bottom: 1rem;
}
.publish_button_group {
  display: flex;
  margin-top: 3rem;
}
</style>